import { getFirebaseAdmin } from "next-firebase-auth";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import initAuth from "./initAuth";
initAuth();

const firestore = firebase.firestore();

export const getParentOrgId = async (orgId) => {
  const orgDoc = await firestore.collection("orgs").doc(orgId).get();
  if (orgDoc.exists) {
    const orgData = orgDoc.data();
    if (orgData.parentOrgId) {
      return orgData.parentOrgId;
    }
  }
  // If orgId is not a child org or doesn't exist
  return null;
};

export const getCurrentOrg = (orgData, orgId) => {
  const currentOrg = orgData.find((el) => {
    return el.orgId === orgId;
  });

  return currentOrg;
};

// TODO: nest websites below orgs in store
export const getOrgWebsites = (orgId, websites) => {
  let orgWebsites = [];
  if (websites) {
    orgWebsites = websites.filter((el) => {
      return el.orgId === orgId;
    });
  }
  return orgWebsites;
};

export const orgHasFeature = async (orgId, feature) => {
  let hasFeature = false;

  const docRef = await firebase.firestore().collection("orgs").doc(orgId).get();
  const orgData = docRef.data();

  if (orgData) {
    const { parentOrgId, planFeatures } = orgData;
    if (parentOrgId) {
      const parentDocRef = await firebase
        .firestore()
        .collection("orgs")
        .doc(parentOrgId)
        .get();
      const parentOrgData = parentDocRef.data();
      if (
        parentOrgData &&
        parentOrgData.planFeatures &&
        parentOrgData.planFeatures.features
      ) {
        hasFeature = parentOrgData.planFeatures.features.includes(feature);
      }
    } else {
      if (planFeatures && planFeatures.features) {
        hasFeature = planFeatures.features.includes(feature);
      }
    }
  }

  return hasFeature;
};

export const getUserMemberships = async (userId) => {
  const queryRef = await firebase
    .firestore()
    .collection("memberships")
    .where("uid", "==", userId)
    .get();

  // console.log('getUserMemberships')
  // console.log(queryRef.docs[0].data().orgId)
  let userOrgsMemberships = [];

  for (let index = 0; index < queryRef.docs.length; index++) {
    const membership = queryRef.docs[index];
    const memberData = membership.data();

    userOrgsMemberships.push({
      orgId: memberData.orgId,
      role: memberData.role,
    });
  }

  console.log(`userOrgsMemberships is `, userOrgsMemberships);

  // const orgId = queryRef?.docs[0]?.data()?.orgId;
  // const hasFeature = docRef.data().planFeatures.features.includes(feature);
  //
  //

  // return orgId;
  return userOrgsMemberships;
};

export const getOrgPublicKey = async (orgId) => {
  const orgDoc = await firestore.collection("orgs").doc(orgId).get();
  if (orgDoc.exists) {
    const orgData = orgDoc.data();
    if (orgData.publicKey) {
      return orgData.publicKey;
    }
  }
  return null;
};
