import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import _ from "lodash"

const SaveRouterHistory = () => {
  const router = useRouter();
  const [visitedPages, setVisitedPages] = useState([]);
  const ignoreRoutes = ['/', '/org/[oid]','/org/[oid]/alerts', '/org/[oid]/website','/org/[oid]/competitors', '/org/[oid]/keywords', '/org/[oid]/page-monitor', '/org/[oid]/ads']

  useEffect(() => {
    const savedPages = localStorage.getItem('visitedPages');
    if (savedPages) {
      setVisitedPages(JSON.parse(savedPages));
    }
  }, []);

  useEffect(() => {
    const currentPath = router.asPath;
    setVisitedPages((prevVisitedPages) => {
      const updatedPages =  [];
      if(prevVisitedPages){
        updatedPages = [currentPath, ...prevVisitedPages?.filter((p) => p !== currentPath).slice(0, 19)];
      }else{
        updatedPages = [currentPath];
      }
      localStorage.setItem('visitedPages', JSON.stringify(updatedPages?.filter((p) => !_.includes(ignoreRoutes, p))));
      return updatedPages;
    });
  }, [router.pathname]);

  return true;
};

export default SaveRouterHistory;