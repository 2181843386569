import React, { createContext, useContext, useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { useRouter } from "next/router";
import { withUser, useUser } from "next-firebase-auth";
import { getParentOrgId } from "../utils/orgDataHelper";

const OrgContext = createContext();

export const OrgProvider = ({ children }) => {
  const [activeOrg, setActiveOrg] = useState(null);
  const AuthUser = useUser();
  const [userLoaded, setUserLoaded] = useState(false); // Track whether AuthUser has loaded
  const [orgsFetched, setOrgsFetched] = useState(false); // Track whether orgs have been fetched
  const [orgs, setOrgs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [parentOrgId, setParentOrgId] = useState(null);
  const router = useRouter();
  const db = firebase.firestore();

  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedOrgValue = localStorage.getItem("activeOrg");
      if (storedOrgValue) {
        setActiveOrg(JSON.parse(storedOrgValue));
      }
    }
  }, []);

  useEffect(() => {
    if (!AuthUser.id) return;

    // console.log("AuthUser:", AuthUser);
    // console.log("orgsFetched:", orgsFetched);

    // Fetch orgs only if AuthUser is available
    if (AuthUser && !orgsFetched) {
      fetchOrgs();
    }
  }, [AuthUser, router, activeOrg, db, orgsFetched]);

  useEffect(() => {
    const orgIdFromUrl = router.query.oid;
    if (orgIdFromUrl && orgs.length > 0) {
      // Find the organization object in orgs that matches the orgIdFromUrl
      const foundOrg = orgs.find((org) => org.orgId === orgIdFromUrl);
      if (foundOrg) {
        setActiveOrg(foundOrg);
        localStorage.setItem("activeOrg", JSON.stringify(foundOrg));
      } else {
        console.error(
          `Organization ID from URL does not match user memberships ${orgs[0].orgId}`
        );
      }
    }
  }, [router.query.oid, orgs, router]);

  const fetchOrgs = async () => {
    // console.log(`fetchOrgs started`);
    try {
      const userId = AuthUser?.id;

      console.log(`fetchOrgs userId `, userId);
      if (!userId) {
        console.error("User not authenticated");
        console.log(`AuthUser is `, AuthUser);
        return;
      }

      const querySnapshot = await firebase
        .firestore()
        .collection("memberships")
        .where("uid", "==", userId)
        .orderBy("created_at")
        .get();
      const orgIds = querySnapshot.docs.map((doc) => doc.data().orgId);

      console.log(`orgIds is `, orgIds);
      const orgPromises = orgIds.map((orgId) =>
        firebase.firestore().collection("orgs").doc(orgId).get()
      );
      const orgDocs = await Promise.all(orgPromises);

      console.log(`orgDocs after promises are `, orgDocs);

      const orgData = orgDocs.map((doc) => ({
        orgId: doc.id,
        ...doc.data(),
      }));

      // Fetch websitesData, integrations, competitors, and dashboards for each org
      const orgsWithData = await Promise.all(
        orgData.map(async (org) => {
          const subscriptionData = await db
            .collection("orgs")
            .doc(org.orgId)
            .collection("subscriptions")
            .get();

          if (subscriptionData.docs.length > 0) {
            org.status = subscriptionData.docs[0].data().status;
          }

          const websitesData = (
            await db
              .collection("websites")
              .where("orgId", "==", org.orgId)
              .get()
          ).docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          org.websites = websitesData;

          console.log(`org org.websites is `)
          console.log(org)

          const integrationData = (
            await db
              .collection("integrations")
              .where("orgId", "==", org.orgId)
              .get()
          ).docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          org.integrations = integrationData;

          const competitorData = (
            await db
              .collection("competitors")
              .where("orgId", "==", org.orgId)
              .get()
          ).docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          org.competitors = competitorData;

          const dashboardsData = (
            await db
              .collection("dashboards")
              .where("orgId", "==", org.orgId)
              .get()
          ).docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          org.dashboards = dashboardsData;

          return org;
        })
      );

      // console.log(`orgsWithData `, orgsWithData);
      setOrgs(orgsWithData);
      setIsLoading(false);

      const { query } = router;
      const orgIdFromUrl = query.oid;

      // Update active org if orgId from URL is different
      if (orgIdFromUrl && activeOrg !== orgIdFromUrl) {
        const foundOrg = orgData.find((org) => org.orgId === orgIdFromUrl);
        if (foundOrg) {
          setActiveOrg(foundOrg);
          localStorage.setItem("activeOrg", JSON.stringify(foundOrg));
          // console.log(`Set foundOrg to `, foundOrg);
        } else {
          // console.log(
          //   `***** org in url is invalide redirecting to ${orgData[0].orgId}`
          // );
          setActiveOrg(orgData[0]);
          localStorage.setItem("activeOrg", JSON.stringify(orgData[0]));
          router.push(`/org/${orgData[0].orgId}/website`);
          console.error(
            "Organization ID from URL does not match user memberships"
          );
        }
      }
      setOrgsFetched(true);
    } catch (error) {
      console.error("Error fetching organizations:", error);
    }
  };

  const updateActiveOrg = (org) => {
    // Update active organization state
    setActiveOrg(org);

    // Store active organization in localStorage
    localStorage.setItem("activeOrg", JSON.stringify(org));
    router.push(`/org/${org.orgId}/website`);
    // Optionally, navigate to the corresponding organization page
    // router.push(`/org/${org.orgId}/website`);
  };

  useEffect(() => {
    const loadParentOrgId = async () => {
      const parentOrgId = await getParentOrgId(activeOrg.orgId);
      setParentOrgId(parentOrgId ? parentOrgId : activeOrg.orgId);
    };
    activeOrg && loadParentOrgId();
  }, [activeOrg]);
  // Backwards compatible create object to pass to useOrg for use on all pages

  return (
    <OrgContext.Provider
      value={{
        orgs,
        isLoading,
        activeOrg,
        updateActiveOrg,
        fetchOrgs,
        parentOrgId,
      }}
    >
      {children}
    </OrgContext.Provider>
  );
};

const OrgWrapper = ({ children }) => {
  return <OrgProvider>{children}</OrgProvider>;
};

export const useOrg = () => useContext(OrgContext);

export default withUser()(OrgWrapper);
